import * as i0 from '@angular/core';
import { Directive, HostBinding, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * A convenience directive for applying styling to a link. Get started with importing the module:
 *
 * ```typescript
 * import { LinkModule } from 'carbon-components-angular';
 * ```
 *
 * ```html
 * <a href="#" cdsLink>A link</a>
 * ```
 *
 * See the [vanilla carbon docs](http://www.carbondesignsystem.com/components/link/code) for more detail.
 *
 * [See demo](../../?path=/story/components-link--basic)
 */
class Link {
  constructor() {
    this.baseClass = true;
    /**
     * Set to true to show links inline in a sentence or paragraph.
     */
    this.inline = false;
  }
  /**
   * Set to true to disable link.
   */
  set disabled(disabled) {
    this._disabled = disabled;
    this.tabindex = this.disabled ? -1 : null;
  }
  get disabled() {
    return this._disabled;
  }
}
Link.ɵfac = function Link_Factory(t) {
  return new (t || Link)();
};
Link.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Link,
  selectors: [["", "cdsLink", ""], ["", "ibmLink", ""]],
  hostVars: 8,
  hostBindings: function Link_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("tabindex", ctx.tabindex)("aria-disabled", ctx.disabled);
      i0.ɵɵclassProp("cds--link", ctx.baseClass)("cds--link--inline", ctx.inline)("cds--link--disabled", ctx.disabled);
    }
  },
  inputs: {
    inline: "inline",
    disabled: "disabled"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Link, [{
    type: Directive,
    args: [{
      selector: "[cdsLink], [ibmLink]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--link"]
    }],
    tabindex: [{
      type: HostBinding,
      args: ["attr.tabindex"]
    }],
    inline: [{
      type: Input
    }, {
      type: HostBinding,
      args: ["class.cds--link--inline"]
    }],
    disabled: [{
      type: Input
    }, {
      type: HostBinding,
      args: ["attr.aria-disabled"]
    }, {
      type: HostBinding,
      args: ["class.cds--link--disabled"]
    }]
  });
})();
class LinkModule {}
LinkModule.ɵfac = function LinkModule_Factory(t) {
  return new (t || LinkModule)();
};
LinkModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LinkModule
});
LinkModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinkModule, [{
    type: NgModule,
    args: [{
      declarations: [Link],
      exports: [Link],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Link, LinkModule };
