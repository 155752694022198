import * as i0 from '@angular/core';
import { Directive, HostBinding, Injectable, EventEmitter, Component, Output, Input, NgModule } from '@angular/core';
import { isObservable, of } from 'rxjs';
import * as i2 from 'carbon-components-angular/i18n';
import { I18nModule } from 'carbon-components-angular/i18n';
import * as i3 from 'carbon-components-angular/button';
import { ButtonModule } from 'carbon-components-angular/button';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from 'carbon-components-angular/link';
import { LinkModule } from 'carbon-components-angular/link';
import * as i4$1 from 'carbon-components-angular/icon';
import { IconModule } from 'carbon-components-angular/icon';
import { ExperimentalModule } from 'carbon-components-angular/experimental';
const _c0 = (a0, a1) => ({
  "cds--inline-notification__icon": a0,
  "cds--toast-notification__icon": a1
});
function ActionableNotification__svg_svg_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 9);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("cdsIcon", ctx_r0.iconDictionary[ctx_r0.notificationObj.type])("ngClass", i0.ɵɵpureFunction2(2, _c0, ctx_r0.notificationObj.variant === "inline", ctx_r0.notificationObj.variant === "toast"));
  }
}
function ActionableNotification_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 10);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r1.notificationObj.title, i0.ɵɵsanitizeHtml);
  }
}
function ActionableNotification_div_5_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "a", 14);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const link_r8 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("href", link_r8.href, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(link_r8.text);
  }
}
function ActionableNotification_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵelement(1, "span", 12);
    i0.ɵɵtemplate(2, ActionableNotification_div_5_ng_container_2_Template, 3, 2, "ng-container", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", ctx_r2.notificationObj.message, i0.ɵɵsanitizeHtml);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.notificationObj.links);
  }
}
function ActionableNotification_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ActionableNotification_ng_container_7_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 16);
    i0.ɵɵlistener("click", function ActionableNotification_ng_container_7_button_1_Template_button_click_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r12);
      const action_r10 = restoredCtx.$implicit;
      const ctx_r11 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r11.onClick(action_r10, $event));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const action_r10 = ctx.$implicit;
    const ctx_r9 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("cdsButton", ctx_r9.notificationObj.variant === "inline" ? "ghost" : "tertiary");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", action_r10.text, " ");
  }
}
function ActionableNotification_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ActionableNotification_ng_container_7_button_1_Template, 2, 2, "button", 15);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r4.notificationObj.actions);
  }
}
function ActionableNotification_ng_container_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ActionableNotification_button_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 17);
    i0.ɵɵlistener("click", function ActionableNotification_button_9_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r14);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.onClose());
    });
    i0.ɵɵpipe(1, "async");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 18);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(1, 1, ctx_r6.notificationObj.closeLabel));
  }
}
const _c1 = a0 => ({
  $implicit: a0
});
function Notification__svg_svg_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("cdsIcon", ctx_r0.iconDictionary[ctx_r0.notificationObj.type]);
  }
}
function Notification_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 8);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r1.notificationObj.title, i0.ɵɵsanitizeHtml);
  }
}
function Notification_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵelement(1, "span", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", ctx_r2.notificationObj.message, i0.ɵɵsanitizeHtml);
  }
}
function Notification_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Notification_button_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 11);
    i0.ɵɵlistener("click", function Notification_button_6_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.onClose());
    });
    i0.ɵɵpipe(1, "async");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(1, 1, ctx_r4.notificationObj.closeLabel));
  }
}
function Toast__svg_svg_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("cdsIcon", ctx_r0.iconDictionary[ctx_r0.notificationObj.type]);
  }
}
function Toast_h3_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "h3", 8);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r1.notificationObj.title, i0.ɵɵsanitizeHtml);
  }
}
function Toast_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵelement(1, "span", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", ctx_r2.notificationObj.subtitle, i0.ɵɵsanitizeHtml);
  }
}
function Toast_p_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "p", 11);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r3.notificationObj.caption, i0.ɵɵsanitizeHtml);
  }
}
function Toast_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Toast_button_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function Toast_button_6_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.onClose());
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵattribute("aria-label", ctx_r5.notificationObj.closeLabel);
  }
}
class ActionableButton {
  constructor() {
    this.actionableButton = true;
    this.type = "button";
  }
}
ActionableButton.ɵfac = function ActionableButton_Factory(t) {
  return new (t || ActionableButton)();
};
ActionableButton.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActionableButton,
  selectors: [["", "cdsActionableButton", ""], ["", "ibmActionableButton", ""]],
  hostVars: 3,
  hostBindings: function ActionableButton_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("type", ctx.type);
      i0.ɵɵclassProp("cds--actionable-notification__action-button", ctx.actionableButton);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionableButton, [{
    type: Directive,
    args: [{
      selector: "[cdsActionableButton], [ibmActionableButton]"
    }]
  }], null, {
    actionableButton: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification__action-button"]
    }],
    type: [{
      type: HostBinding,
      args: ["attr.type"]
    }]
  });
})();
class ActionableSubtitle {
  constructor() {
    this.baseClass = true;
  }
}
ActionableSubtitle.ɵfac = function ActionableSubtitle_Factory(t) {
  return new (t || ActionableSubtitle)();
};
ActionableSubtitle.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActionableSubtitle,
  selectors: [["", "cdsActionableSubtitle", ""], ["", "ibmActionableSubtitle", ""]],
  hostVars: 2,
  hostBindings: function ActionableSubtitle_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--actionable-notification__subtitle", ctx.baseClass);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionableSubtitle, [{
    type: Directive,
    args: [{
      selector: "[cdsActionableSubtitle], [ibmActionableSubtitle]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification__subtitle"]
    }]
  });
})();
class ActionableTitle {
  constructor() {
    this.baseClass = true;
  }
}
ActionableTitle.ɵfac = function ActionableTitle_Factory(t) {
  return new (t || ActionableTitle)();
};
ActionableTitle.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActionableTitle,
  selectors: [["", "cdsActionableTitle", ""], ["", "ibmActionableTitle", ""]],
  hostVars: 2,
  hostBindings: function ActionableTitle_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--actionable-notification__title", ctx.baseClass);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionableTitle, [{
    type: Directive,
    args: [{
      selector: "[cdsActionableTitle], [ibmActionableTitle]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification__title"]
    }]
  });
})();
class NotificationDisplayService {
  constructor(applicationRef) {
    this.applicationRef = applicationRef;
  }
  /**
   * Programatically closes notification based on `notificationRef`.	 *
   */
  close(notificationRef) {
    if (notificationRef.hostView) {
      setTimeout(() => {
        this.applicationRef.detachView(notificationRef.hostView);
        notificationRef.destroy();
      }, 200);
    }
  }
}
NotificationDisplayService.ɵfac = function NotificationDisplayService_Factory(t) {
  return new (t || NotificationDisplayService)(i0.ɵɵinject(i0.ApplicationRef));
};
NotificationDisplayService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NotificationDisplayService,
  factory: NotificationDisplayService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationDisplayService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ApplicationRef
    }];
  }, null);
})();

/**
 * Base class for `Notification`, `ActionableNotification`, & `Toast`
 * consisting of common functionality
 *
 * Get started with importing the module:
 *
 * ```typescript
 * import { NotificationModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-notification--inline-notification)
 */
class BaseNotification {
  constructor(notificationDisplayService, i18n) {
    this.notificationDisplayService = notificationDisplayService;
    this.i18n = i18n;
    /**
     * Emits on close.
     */
    this.close = new EventEmitter();
    // Get icon name(value) for service based on the notification type (key)
    this.iconDictionary = {
      "error": "error--filled",
      "info": "information--filled",
      "info-square": "information--square--filled",
      "success": "checkmark--filled",
      "warning": "warning--filled",
      "warning-alt": "warning--alt--filled"
    };
    this.defaultNotificationObj = {
      title: "",
      message: "",
      type: "info",
      showClose: true,
      closeLabel: this.i18n.get("NOTIFICATION.CLOSE_BUTTON"),
      role: "status"
    };
    this._notificationObj = Object.assign({}, this.defaultNotificationObj);
  }
  /**
   * Set role attribute for component
   * `Status` is default for inline-notification & toast component
   * `alertdialog` is default for actionable-notification
   */
  get roleAttr() {
    return this._notificationObj.role;
  }
  /**
   * Emits close event.
   */
  onClose() {
    this.close.emit();
  }
  onClick(action, event) {
    if (!action.click) {
      return;
    }
    if (isObservable(action.click)) {
      action.click.next({
        event,
        action
      });
    } else {
      action.click({
        event,
        action
      });
    }
  }
  destroy() {
    this.notificationDisplayService.close(this);
  }
}
BaseNotification.ɵfac = function BaseNotification_Factory(t) {
  return new (t || BaseNotification)(i0.ɵɵdirectiveInject(NotificationDisplayService), i0.ɵɵdirectiveInject(i2.I18n));
};
BaseNotification.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BaseNotification,
  selectors: [["ng-component"]],
  hostVars: 1,
  hostBindings: function BaseNotification_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.roleAttr);
    }
  },
  outputs: {
    close: "close"
  },
  decls: 0,
  vars: 0,
  template: function BaseNotification_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseNotification, [{
    type: Component,
    args: [{
      template: ""
    }]
  }], function () {
    return [{
      type: NotificationDisplayService
    }, {
      type: i2.I18n
    }];
  }, {
    roleAttr: [{
      type: HostBinding,
      args: ["attr.role"]
    }],
    close: [{
      type: Output
    }]
  });
})();

/**
 * Actionable notification allows for interactive elements within a notification. There are two variants offered, inline & toast.
 *
 * [See demo](../../?path=/story/components-notification--actionable-notification)
 */
class ActionableNotification extends BaseNotification {
  constructor(notificationDisplayService, i18n) {
    super(notificationDisplayService, i18n);
    this.notificationDisplayService = notificationDisplayService;
    this.i18n = i18n;
    this.notificationID = `notification-${ActionableNotification.notificationCount++}`;
    this.notificationClass = true;
    /**
     * Set default variant & role, alternatives can be provided through notificationObj property
     */
    this.defaultNotificationObj = {
      ...this.defaultNotificationObj,
      variant: "inline",
      role: "alertdialog"
    };
  }
  /**
   * Can have `type`, `title`, and `message` members.
   *
   * `type` can be one of `"error"`, `"info"`, `"info-square"`, `"warning"`, `"warning-alt"`, or `"success"`
   *
   * `message` is the message to display
   */
  get notificationObj() {
    return this._notificationObj;
  }
  set notificationObj(obj) {
    if (obj.closeLabel) {
      obj.closeLabel = of(obj.closeLabel);
    }
    this._notificationObj = Object.assign({}, this.defaultNotificationObj, obj);
  }
  get toastVariant() {
    return this.notificationObj.variant === "toast";
  }
  get isError() {
    return this.notificationObj.type === "error";
  }
  get isInfo() {
    return this.notificationObj.type === "info";
  }
  get isInfoSquare() {
    return this.notificationObj.type === "info-square";
  }
  get isSuccess() {
    return this.notificationObj.type === "success";
  }
  get isWarning() {
    return this.notificationObj.type === "warning";
  }
  get isWarningAlt() {
    return this.notificationObj.type === "warning-alt";
  }
  get isLowContrast() {
    return this.notificationObj.lowContrast;
  }
  get isCloseHidden() {
    return !this._notificationObj.showClose;
  }
}
ActionableNotification.notificationCount = 0;
ActionableNotification.ɵfac = function ActionableNotification_Factory(t) {
  return new (t || ActionableNotification)(i0.ɵɵdirectiveInject(NotificationDisplayService), i0.ɵɵdirectiveInject(i2.I18n));
};
ActionableNotification.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ActionableNotification,
  selectors: [["cds-actionable-notification"], ["ibm-actionable-notification"]],
  hostVars: 21,
  hostBindings: function ActionableNotification_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("id", ctx.notificationID);
      i0.ɵɵclassProp("cds--actionable-notification", ctx.notificationClass)("cds--actionable-notification--toast", ctx.toastVariant)("cds--actionable-notification--error", ctx.isError)("cds--actionable-notification--info", ctx.isInfo)("cds--actionable-notification--info-square", ctx.isInfoSquare)("cds--actionable-notification--success", ctx.isSuccess)("cds--actionable-notification--warning", ctx.isWarning)("cds--actionable-notification--warning-alt", ctx.isWarningAlt)("cds--actionable-notification--low-contrast", ctx.isLowContrast)("cds--actionable-notification--hide-close-button", ctx.isCloseHidden);
    }
  },
  inputs: {
    notificationObj: "notificationObj"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 10,
  vars: 13,
  consts: [[1, "cds--actionable-notification__details"], ["size", "20", "class", "cds--actionable-notification__icon", 3, "cdsIcon", "ngClass", 4, "ngIf"], [1, "cds--actionable-notification__text-wrapper"], [1, "cds--actionable-notification__content"], ["cdsActionableTitle", "", 3, "innerHTML", 4, "ngIf"], ["cdsActionableSubtitle", "", 4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [4, "ngIf"], ["class", "cds--actionable-notification__close-button", "type", "button", 3, "click", 4, "ngIf"], ["size", "20", 1, "cds--actionable-notification__icon", 3, "cdsIcon", "ngClass"], ["cdsActionableTitle", "", 3, "innerHTML"], ["cdsActionableSubtitle", ""], [3, "innerHTML"], [4, "ngFor", "ngForOf"], ["cdsLink", "", 3, "href"], ["size", "sm", "cdsActionableButton", "", 3, "cdsButton", "click", 4, "ngFor", "ngForOf"], ["size", "sm", "cdsActionableButton", "", 3, "cdsButton", "click"], ["type", "button", 1, "cds--actionable-notification__close-button", 3, "click"], ["cdsIcon", "close", "size", "16", 1, "cds--actionable-notification__close-icon"]],
  template: function ActionableNotification_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, ActionableNotification__svg_svg_1_Template, 1, 5, "svg", 1);
      i0.ɵɵelementStart(2, "div", 2)(3, "div", 3);
      i0.ɵɵtemplate(4, ActionableNotification_div_4_Template, 1, 1, "div", 4)(5, ActionableNotification_div_5_Template, 3, 2, "div", 5)(6, ActionableNotification_ng_container_6_Template, 1, 0, "ng-container", 6);
      i0.ɵɵelementEnd()()();
      i0.ɵɵtemplate(7, ActionableNotification_ng_container_7_Template, 2, 1, "ng-container", 7)(8, ActionableNotification_ng_container_8_Template, 1, 0, "ng-container", 6)(9, ActionableNotification_button_9_Template, 3, 3, "button", 8);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.notificationObj.type);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", !ctx.notificationObj.template);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.notificationObj.template);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngTemplateOutlet", ctx.notificationObj.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(9, _c1, ctx.notificationObj));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.notificationObj.actionsTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngTemplateOutlet", ctx.notificationObj.actionsTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(11, _c1, ctx.notificationObj));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.isCloseHidden);
    }
  },
  dependencies: [i3.Button, i4.NgClass, i4.NgForOf, i4.NgIf, i4.NgTemplateOutlet, i5.Link, i4$1.IconDirective, ActionableButton, ActionableTitle, ActionableSubtitle, i4.AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionableNotification, [{
    type: Component,
    args: [{
      selector: "cds-actionable-notification, ibm-actionable-notification",
      template: `
		<div class="cds--actionable-notification__details">
			<svg
				[cdsIcon]="iconDictionary[notificationObj.type]"
				size="20"
				*ngIf="notificationObj.type"
				[ngClass]="{
					'cds--inline-notification__icon': notificationObj.variant === 'inline',
					'cds--toast-notification__icon': notificationObj.variant === 'toast'
				}"
				class="cds--actionable-notification__icon">
			</svg>
			<div class="cds--actionable-notification__text-wrapper">
				<div class="cds--actionable-notification__content">
					<div *ngIf="!notificationObj.template" cdsActionableTitle [innerHTML]="notificationObj.title"></div>
					<div *ngIf="!notificationObj.template" cdsActionableSubtitle>
						<span [innerHTML]="notificationObj.message"></span>
						<ng-container *ngFor="let link of notificationObj.links">
							<a cdsLink [href]="link.href">{{link.text}}</a>
						</ng-container>
					</div>
					<ng-container *ngTemplateOutlet="notificationObj.template; context: { $implicit: notificationObj }"></ng-container>
				</div>
			</div>
		</div>
		<ng-container *ngIf="!notificationObj.actionsTemplate">
			<button
				*ngFor="let action of notificationObj.actions"
				(click)="onClick(action, $event)"
				[cdsButton]="notificationObj.variant === 'inline' ? 'ghost' : 'tertiary'"
				size="sm"
				cdsActionableButton>
				{{action.text}}
			</button>
		</ng-container>
		<ng-container *ngTemplateOutlet="notificationObj.actionsTemplate; context: { $implicit: notificationObj }"></ng-container>
		<button
			*ngIf="!isCloseHidden"
			(click)="onClose()"
			class="cds--actionable-notification__close-button"
			[attr.aria-label]="notificationObj.closeLabel | async"
			type="button">
			<svg cdsIcon="close" size="16" class="cds--actionable-notification__close-icon"></svg>
		</button>
	`
    }]
  }], function () {
    return [{
      type: NotificationDisplayService
    }, {
      type: i2.I18n
    }];
  }, {
    notificationObj: [{
      type: Input
    }],
    notificationID: [{
      type: HostBinding,
      args: ["attr.id"]
    }],
    notificationClass: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification"]
    }],
    toastVariant: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--toast"]
    }],
    isError: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--error"]
    }],
    isInfo: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--info"]
    }],
    isInfoSquare: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--info-square"]
    }],
    isSuccess: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--success"]
    }],
    isWarning: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--warning"]
    }],
    isWarningAlt: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--warning-alt"]
    }],
    isLowContrast: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--low-contrast"]
    }],
    isCloseHidden: [{
      type: HostBinding,
      args: ["class.cds--actionable-notification--hide-close-button"]
    }]
  });
})();
class NotificationSubtitle {
  constructor() {
    this.baseClass = true;
  }
}
NotificationSubtitle.ɵfac = function NotificationSubtitle_Factory(t) {
  return new (t || NotificationSubtitle)();
};
NotificationSubtitle.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NotificationSubtitle,
  selectors: [["", "cdsNotificationSubtitle", ""], ["", "ibmNotificationSubtitle", ""]],
  hostVars: 2,
  hostBindings: function NotificationSubtitle_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--inline-notification__subtitle", ctx.baseClass);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationSubtitle, [{
    type: Directive,
    args: [{
      selector: "[cdsNotificationSubtitle], [ibmNotificationSubtitle]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--inline-notification__subtitle"]
    }]
  });
})();
class NotificationTitle {
  constructor() {
    this.baseClass = true;
  }
}
NotificationTitle.ɵfac = function NotificationTitle_Factory(t) {
  return new (t || NotificationTitle)();
};
NotificationTitle.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NotificationTitle,
  selectors: [["", "cdsNotificationTitle", ""], ["", "ibmNotificationTitle", ""]],
  hostVars: 2,
  hostBindings: function NotificationTitle_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--inline-notification__title", ctx.baseClass);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationTitle, [{
    type: Directive,
    args: [{
      selector: "[cdsNotificationTitle], [ibmNotificationTitle]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--inline-notification__title"]
    }]
  });
})();

/**
 * Notification messages are displayed toward the top of the UI and do not interrupt user’s work.
 *
 * [See demo](../../?path=/story/components-notification--basic)
 */
class Notification extends BaseNotification {
  constructor(notificationDisplayService, i18n) {
    super(notificationDisplayService, i18n);
    this.notificationDisplayService = notificationDisplayService;
    this.i18n = i18n;
    this.notificationID = `notification-${Notification.notificationCount++}`;
    this.notificationClass = true;
  }
  /**
   * Can have `type`, `title`, and `message` members.
   *
   * `type` can be one of `"error"`, `"info"`, `"info-square"`, `"warning"`, `"warning-alt"`, or `"success"`
   *
   * `message` is the message to display
   */
  get notificationObj() {
    return this._notificationObj;
  }
  set notificationObj(obj) {
    if (obj.closeLabel) {
      obj.closeLabel = of(obj.closeLabel);
    }
    this._notificationObj = Object.assign({}, this.defaultNotificationObj, obj);
  }
  get isError() {
    return this.notificationObj.type === "error";
  }
  get isInfo() {
    return this.notificationObj.type === "info";
  }
  get isInfoSquare() {
    return this.notificationObj.type === "info-square";
  }
  get isSuccess() {
    return this.notificationObj.type === "success";
  }
  get isWarning() {
    return this.notificationObj.type === "warning";
  }
  get isWarningAlt() {
    return this.notificationObj.type === "warning-alt";
  }
  get isLowContrast() {
    return this.notificationObj.lowContrast;
  }
  get isCloseHidden() {
    return !this.notificationObj.showClose;
  }
}
Notification.notificationCount = 0;
Notification.ɵfac = function Notification_Factory(t) {
  return new (t || Notification)(i0.ɵɵdirectiveInject(NotificationDisplayService), i0.ɵɵdirectiveInject(i2.I18n));
};
Notification.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Notification,
  selectors: [["cds-notification"], ["cds-inline-notification"], ["ibm-notification"], ["ibm-inline-notification"]],
  hostVars: 19,
  hostBindings: function Notification_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("id", ctx.notificationID);
      i0.ɵɵclassProp("cds--inline-notification", ctx.notificationClass)("cds--inline-notification--error", ctx.isError)("cds--inline-notification--info", ctx.isInfo)("cds--inline-notification--info-square", ctx.isInfoSquare)("cds--inline-notification--success", ctx.isSuccess)("cds--inline-notification--warning", ctx.isWarning)("cds--inline-notification--warning-alt", ctx.isWarningAlt)("cds--inline-notification--low-contrast", ctx.isLowContrast)("cds--inline-notification--hide-close-button", ctx.isCloseHidden);
    }
  },
  inputs: {
    notificationObj: "notificationObj"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 7,
  vars: 8,
  consts: [[1, "cds--inline-notification__details"], ["size", "20", "class", "cds--inline-notification__icon", 3, "cdsIcon", 4, "ngIf"], [1, "cds--inline-notification__text-wrapper"], ["cdsNotificationTitle", "", 3, "innerHTML", 4, "ngIf"], ["cdsNotificationSubtitle", "", 4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["class", "cds--inline-notification__close-button", "type", "button", 3, "click", 4, "ngIf"], ["size", "20", 1, "cds--inline-notification__icon", 3, "cdsIcon"], ["cdsNotificationTitle", "", 3, "innerHTML"], ["cdsNotificationSubtitle", ""], [3, "innerHTML"], ["type", "button", 1, "cds--inline-notification__close-button", 3, "click"], ["cdsIcon", "close", "size", "16", 1, "cds--inline-notification__close-icon"]],
  template: function Notification_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, Notification__svg_svg_1_Template, 1, 1, "svg", 1);
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵtemplate(3, Notification_div_3_Template, 1, 1, "div", 3)(4, Notification_div_4_Template, 2, 1, "div", 4)(5, Notification_ng_container_5_Template, 1, 0, "ng-container", 5);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(6, Notification_button_6_Template, 3, 3, "button", 6);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.notificationObj.type);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.notificationObj.template);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.notificationObj.template);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngTemplateOutlet", ctx.notificationObj.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(6, _c1, ctx.notificationObj));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.isCloseHidden);
    }
  },
  dependencies: [i4.NgIf, i4.NgTemplateOutlet, i4$1.IconDirective, NotificationTitle, NotificationSubtitle, i4.AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Notification, [{
    type: Component,
    args: [{
      selector: "cds-notification, cds-inline-notification, ibm-notification, ibm-inline-notification",
      template: `
		<div class="cds--inline-notification__details">
			<svg
				[cdsIcon]="iconDictionary[notificationObj.type]"
				size="20"
				*ngIf="notificationObj.type"
				class="cds--inline-notification__icon">
			</svg>
			<div class="cds--inline-notification__text-wrapper">
				<div *ngIf="!notificationObj.template" cdsNotificationTitle [innerHTML]="notificationObj.title"></div>
				<div *ngIf="!notificationObj.template" cdsNotificationSubtitle>
					<span [innerHTML]="notificationObj.message"></span>
				</div>
				<ng-container *ngTemplateOutlet="notificationObj.template; context: { $implicit: notificationObj}"></ng-container>
			</div>
		</div>
		<button
			*ngIf="!isCloseHidden"
			(click)="onClose()"
			class="cds--inline-notification__close-button"
			[attr.aria-label]="notificationObj.closeLabel | async"
			type="button">
			<svg cdsIcon="close" size="16" class="cds--inline-notification__close-icon"></svg>
		</button>
	`
    }]
  }], function () {
    return [{
      type: NotificationDisplayService
    }, {
      type: i2.I18n
    }];
  }, {
    notificationObj: [{
      type: Input
    }],
    notificationID: [{
      type: HostBinding,
      args: ["attr.id"]
    }],
    notificationClass: [{
      type: HostBinding,
      args: ["class.cds--inline-notification"]
    }],
    isError: [{
      type: HostBinding,
      args: ["class.cds--inline-notification--error"]
    }],
    isInfo: [{
      type: HostBinding,
      args: ["class.cds--inline-notification--info"]
    }],
    isInfoSquare: [{
      type: HostBinding,
      args: ["class.cds--inline-notification--info-square"]
    }],
    isSuccess: [{
      type: HostBinding,
      args: ["class.cds--inline-notification--success"]
    }],
    isWarning: [{
      type: HostBinding,
      args: ["class.cds--inline-notification--warning"]
    }],
    isWarningAlt: [{
      type: HostBinding,
      args: ["class.cds--inline-notification--warning-alt"]
    }],
    isLowContrast: [{
      type: HostBinding,
      args: ["class.cds--inline-notification--low-contrast"]
    }],
    isCloseHidden: [{
      type: HostBinding,
      args: ["class.cds--inline-notification--hide-close-button"]
    }]
  });
})();
class ToastTitle {
  constructor() {
    this.baseClass = true;
  }
}
ToastTitle.ɵfac = function ToastTitle_Factory(t) {
  return new (t || ToastTitle)();
};
ToastTitle.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ToastTitle,
  selectors: [["", "cdsToastTitle", ""], ["", "ibmToastTitle", ""]],
  hostVars: 2,
  hostBindings: function ToastTitle_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--toast-notification__title", ctx.baseClass);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastTitle, [{
    type: Directive,
    args: [{
      selector: "[cdsToastTitle], [ibmToastTitle]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--toast-notification__title"]
    }]
  });
})();
class ToastSubtitle {
  constructor() {
    this.baseClass = true;
  }
}
ToastSubtitle.ɵfac = function ToastSubtitle_Factory(t) {
  return new (t || ToastSubtitle)();
};
ToastSubtitle.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ToastSubtitle,
  selectors: [["", "cdsToastSubtitle", ""], ["", "ibmToastSubtitle", ""]],
  hostVars: 2,
  hostBindings: function ToastSubtitle_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--toast-notification__subtitle", ctx.baseClass);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastSubtitle, [{
    type: Directive,
    args: [{
      selector: "[cdsToastSubtitle], [ibmToastSubtitle]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--toast-notification__subtitle"]
    }]
  });
})();
class ToastCaption {
  constructor() {
    this.baseClass = true;
  }
}
ToastCaption.ɵfac = function ToastCaption_Factory(t) {
  return new (t || ToastCaption)();
};
ToastCaption.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ToastCaption,
  selectors: [["", "cdsToastCaption", ""], ["", "ibmToastCaption", ""]],
  hostVars: 2,
  hostBindings: function ToastCaption_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--toast-notification__caption", ctx.baseClass);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastCaption, [{
    type: Directive,
    args: [{
      selector: "[cdsToastCaption], [ibmToastCaption]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--toast-notification__caption"]
    }]
  });
})();

/**
 * Toast messages are displayed toward the top of the UI and do not interrupt user’s work.
 *
 * [See demo](../../?path=/story/components-notification--toast)
 */
class Toast extends BaseNotification {
  constructor(notificationDisplayService, i18n) {
    super(notificationDisplayService, i18n);
    this.notificationDisplayService = notificationDisplayService;
    this.i18n = i18n;
    this.toastID = `toast-${Toast.toastCount++}`;
    this.toastClass = true;
  }
  get isError() {
    return this.notificationObj.type === "error";
  }
  get isInfo() {
    return this.notificationObj.type === "info";
  }
  get isInfoSquare() {
    return this.notificationObj.type === "info-square";
  }
  get isSuccess() {
    return this.notificationObj.type === "success";
  }
  get isWarning() {
    return this.notificationObj.type === "warning";
  }
  get isWarningAlt() {
    return this.notificationObj.type === "warning-alt";
  }
  get isLowContrast() {
    return this.notificationObj.lowContrast;
  }
  get isCloseHidden() {
    return !this.notificationObj.showClose;
  }
  ngOnInit() {
    if (!this.notificationObj.closeLabel) {
      this.notificationObj.closeLabel = this.i18n.get().NOTIFICATION.CLOSE_BUTTON;
    }
  }
}
Toast.toastCount = 0;
Toast.ɵfac = function Toast_Factory(t) {
  return new (t || Toast)(i0.ɵɵdirectiveInject(NotificationDisplayService), i0.ɵɵdirectiveInject(i2.I18n));
};
Toast.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Toast,
  selectors: [["cds-toast"], ["ibm-toast"]],
  hostVars: 19,
  hostBindings: function Toast_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("id", ctx.toastID);
      i0.ɵɵclassProp("cds--toast-notification", ctx.toastClass)("cds--toast-notification--error", ctx.isError)("cds--toast-notification--info", ctx.isInfo)("cds--toast-notification--info-square", ctx.isInfoSquare)("cds--toast-notification--success", ctx.isSuccess)("cds--toast-notification--warning", ctx.isWarning)("cds--toast-notification--warning-alt", ctx.isWarningAlt)("cds--toast-notification--low-contrast", ctx.isLowContrast)("cds--toast-notification--hide-close-button", ctx.isCloseHidden);
    }
  },
  inputs: {
    notificationObj: "notificationObj"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 7,
  vars: 9,
  consts: [["size", "20", "class", "cds--toast-notification__icon", 3, "cdsIcon", 4, "ngIf"], [1, "cds--toast-notification__details"], ["cdsToastTitle", "", 3, "innerHTML", 4, "ngIf"], ["cdsToastSubtitle", "", 4, "ngIf"], ["cdsToastCaption", "", 3, "innerHTML", 4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["class", "cds--toast-notification__close-button", "type", "button", 3, "click", 4, "ngIf"], ["size", "20", 1, "cds--toast-notification__icon", 3, "cdsIcon"], ["cdsToastTitle", "", 3, "innerHTML"], ["cdsToastSubtitle", ""], [3, "innerHTML"], ["cdsToastCaption", "", 3, "innerHTML"], ["type", "button", 1, "cds--toast-notification__close-button", 3, "click"], ["cdsIcon", "close", "size", "16", 1, "cds--toast-notification__close-icon"]],
  template: function Toast_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, Toast__svg_svg_0_Template, 1, 1, "svg", 0);
      i0.ɵɵelementStart(1, "div", 1);
      i0.ɵɵtemplate(2, Toast_h3_2_Template, 1, 1, "h3", 2)(3, Toast_div_3_Template, 2, 1, "div", 3)(4, Toast_p_4_Template, 1, 1, "p", 4)(5, Toast_ng_container_5_Template, 1, 0, "ng-container", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(6, Toast_button_6_Template, 2, 1, "button", 6);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.notificationObj.type);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.notificationObj.template);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.notificationObj.template);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.notificationObj.template);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngTemplateOutlet", ctx.notificationObj.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(7, _c1, ctx.notificationObj));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.isCloseHidden);
    }
  },
  dependencies: [i4.NgIf, i4.NgTemplateOutlet, i4$1.IconDirective, ToastTitle, ToastSubtitle, ToastCaption],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Toast, [{
    type: Component,
    args: [{
      selector: "cds-toast, ibm-toast",
      template: `
		<svg
			[cdsIcon]="iconDictionary[notificationObj.type]"
			size="20"
			*ngIf="notificationObj.type"
			class="cds--toast-notification__icon">
		</svg>
		<div class="cds--toast-notification__details">
			<h3 *ngIf="!notificationObj.template" cdsToastTitle [innerHTML]="notificationObj.title"></h3>
			<div *ngIf="!notificationObj.template" cdsToastSubtitle>
				<span [innerHTML]="notificationObj.subtitle"></span>
			</div>
			<p *ngIf="!notificationObj.template" cdsToastCaption [innerHTML]="notificationObj.caption"></p>
			<ng-container *ngTemplateOutlet="notificationObj.template; context: { $implicit: notificationObj }"></ng-container>
		</div>
		<button
			*ngIf="!isCloseHidden"
			class="cds--toast-notification__close-button"
			type="button"
			[attr.aria-label]="notificationObj.closeLabel"
			(click)="onClose()">
			<svg cdsIcon="close" size="16" class="cds--toast-notification__close-icon"></svg>
		</button>
	`
    }]
  }], function () {
    return [{
      type: NotificationDisplayService
    }, {
      type: i2.I18n
    }];
  }, {
    notificationObj: [{
      type: Input
    }],
    toastID: [{
      type: HostBinding,
      args: ["attr.id"]
    }],
    toastClass: [{
      type: HostBinding,
      args: ["class.cds--toast-notification"]
    }],
    isError: [{
      type: HostBinding,
      args: ["class.cds--toast-notification--error"]
    }],
    isInfo: [{
      type: HostBinding,
      args: ["class.cds--toast-notification--info"]
    }],
    isInfoSquare: [{
      type: HostBinding,
      args: ["class.cds--toast-notification--info-square"]
    }],
    isSuccess: [{
      type: HostBinding,
      args: ["class.cds--toast-notification--success"]
    }],
    isWarning: [{
      type: HostBinding,
      args: ["class.cds--toast-notification--warning"]
    }],
    isWarningAlt: [{
      type: HostBinding,
      args: ["class.cds--toast-notification--warning-alt"]
    }],
    isLowContrast: [{
      type: HostBinding,
      args: ["class.cds--toast-notification--low-contrast"]
    }],
    isCloseHidden: [{
      type: HostBinding,
      args: ["class.cds--toast-notification--hide-close-button"]
    }]
  });
})();

/**
 * Provides a way to use the notification component.
 *
 * Notifications are displayed toward the top of the UI and do not interrupt the user’s work.
 */
class NotificationService {
  /**
   * Constructs Notification Service
   */
  constructor(injector, viewContainer, ngZone) {
    this.injector = injector;
    this.viewContainer = viewContainer;
    this.ngZone = ngZone;
    /**
     * An array containing `ComponentRef`s to all the notifications this service instance
     * is responsible for.
     *
     */
    this.notificationRefs = new Array();
    this.onClose = new EventEmitter();
  }
  /**
   * Shows the notification based on the `notificationObj`.
   *
   * @param notificationObj Can have `type`, `message`, `target`, `duration` and `smart` members.
   *
   * **Members:**
   *
   * * `type` can be one of `"info"`, `"warning"`, `"danger"`, `"success"`
   * * `message` is message for notification to display
   * * `target` is css selector defining an element to append notification to. If not provided,
   * `showNotification()` creates a place for the notification in `body`
   * * `duration` is number of ms to close the notification after. If used in combination with `smart`,
   * it's added to the calculated timeout
   * * `smart`, set to `true` if you want to use smart notification.
   *
   * **Example:**
   * ```typescript
   * // Info notification, saying "Sample message." added to the element with id notification-container
   * // uses smart timeout with added duration of 1 second.
   * {
   *	type: "info",
   *	message: "Sample message.",
   *	target: "#notification-container",
   *	duration: 1000,
   *	smart: true
   * }
   * ```
   *
   * @param [notificationComp=Notification] If provided, used to resolve component factory
   */
  showNotification(notificationObj, notificationComp = Notification) {
    const notificationRef = this.viewContainer.createComponent(notificationComp);
    notificationRef.instance.notificationObj = notificationObj;
    this.notificationRefs.push(notificationRef);
    this.onClose = notificationRef.instance.close;
    if (notificationObj.target) {
      document.querySelector(notificationObj.target).appendChild(notificationRef.location.nativeElement);
    } else {
      let body = document.querySelector("body");
      // get or create a container for alert list
      let notificationClassName = "notification-overlay";
      let notificationList = body.querySelector(`.${notificationClassName}`);
      if (!notificationList) {
        notificationList = document.createElement("div");
        notificationList.className = notificationClassName;
        body.appendChild(notificationList);
      }
      // add the notification to the top of the list
      if (notificationList.firstChild) {
        notificationList.insertBefore(notificationRef.location.nativeElement, notificationList.firstChild);
      } else {
        notificationList.appendChild(notificationRef.location.nativeElement);
      }
    }
    if (notificationObj.duration && notificationObj.duration > 0) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.ngZone.run(() => {
            this.close(notificationRef);
          });
        }, notificationObj.duration);
      });
    }
    if (notificationObj.smart) {
      this.ngZone.runOutsideAngular(() => {
        // let it disappear after calculated timeout
        setTimeout(() => {
          this.ngZone.run(() => {
            this.close(notificationRef);
          });
        }, this.getSmartTimeout(notificationObj));
      });
    }
    this.onClose.subscribe(() => {
      this.close(notificationRef);
    });
    notificationRef.instance.componentRef = notificationRef;
    return notificationRef.instance;
  }
  showToast(notificationObj, notificationComp = Toast) {
    return this.showNotification(notificationObj, notificationComp);
  }
  showActionable(notificationObj, notificationComp = ActionableNotification) {
    return this.showNotification(notificationObj, notificationComp);
  }
  /**
   * Programatically closes notification based on `notificationRef`.
   *
   * @param notificationRef `ComponentRef` of a notification or `Notification` component you wish to close
   */
  close(notificationRef) {
    if (notificationRef) {
      if (notificationRef instanceof Notification) {
        this.close(notificationRef.componentRef);
      } else {
        notificationRef.destroy();
        const index = this.notificationRefs.indexOf(notificationRef);
        if (index !== -1) {
          this.notificationRefs.splice(index, 1);
        }
      }
    }
  }
  /**
   * Calculates the amount of time user needs to read the message in the notification.
   *
   * @param notificationObj Same object used to instantiate notification.
   *
   * In addition to `type` and `message` members, use `duration` member to add
   * some extra time (in ms) to timeout if you need to.
   * @returns calculated timeout (in ms) for smart notification
   */
  getSmartTimeout(notificationObj) {
    // calculate timeout
    let timeout = 600; // start with reaction time
    // custom duration
    timeout += notificationObj.duration || 0;
    // message type
    switch (notificationObj.type) {
      case "info":
      case "info-square":
      case "success":
      default:
        {
          break;
        }
      case "danger":
        {
          timeout += 3000;
          break;
        }
      case "warning":
      case "warning-alt":
        {
          timeout += 1500;
          break;
        }
    }
    // message length
    // average reader reads around 200 words per minute, or it takes them ~0.3s per word
    // let's use 1.5 factor for below average speed readers and have 0.45s per word
    let wordCount = notificationObj.message.trim().split(/\s+/).length;
    timeout += wordCount * 450;
    return timeout;
  }
  /**
   * OnDestroy hook.
   *
   * Destroys all living notifications it is responsible for.
   *
   */
  ngOnDestroy() {
    this.notificationRefs.forEach(ref => {
      ref.destroy();
    });
  }
}
NotificationService.ɵfac = function NotificationService_Factory(t) {
  return new (t || NotificationService)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i0.ViewContainerRef), i0.ɵɵinject(i0.NgZone));
};
NotificationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NotificationService,
  factory: NotificationService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
class NotificationModule {}
NotificationModule.ɵfac = function NotificationModule_Factory(t) {
  return new (t || NotificationModule)();
};
NotificationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NotificationModule
});
NotificationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [NotificationService, NotificationDisplayService],
  imports: [ButtonModule, CommonModule, I18nModule, ExperimentalModule, LinkModule, IconModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationModule, [{
    type: NgModule,
    args: [{
      declarations: [ActionableButton, ActionableNotification, ActionableTitle, ActionableSubtitle, BaseNotification, Notification, Toast, ToastTitle, ToastSubtitle, ToastCaption, NotificationTitle, NotificationSubtitle],
      exports: [Notification, ActionableButton, ActionableNotification, ActionableTitle, ActionableSubtitle, Toast, ToastTitle, ToastSubtitle, ToastCaption, NotificationTitle, NotificationSubtitle],
      imports: [ButtonModule, CommonModule, I18nModule, ExperimentalModule, LinkModule, IconModule],
      providers: [NotificationService, NotificationDisplayService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ActionableButton, ActionableNotification, ActionableSubtitle, ActionableTitle, BaseNotification, Notification, NotificationDisplayService, NotificationModule, NotificationService, NotificationSubtitle, NotificationTitle, Toast, ToastCaption, ToastSubtitle, ToastTitle };
